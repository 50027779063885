*{
  margin:0;
  padding: 0;  
  box-sizing: border-box;
}
html, body{
  scroll-behavior: smooth;
}

body{
  width: 100%;
  background-color: #EDECE3;
  overflow-x: hidden;
}
::-webkit-scrollbar{
  width: 5px;
}

#root{
  display: flex;
  justify-content: center;
}

.flex{
  display: flex;
}
.column{
  flex-direction: column;
}
.align-c{
  align-items: center;
}
.justify-c{
  justify-content: center;
}
.w100{
  width: 100%;
}
.h100{
  height:100% ;
}
.w100vw{
  width: 100vw;
}
.h100vh{
  height:100vh;
}

.loader{
  height: 80vh;
  font-family: 'Bodoni Moda', serif;
}

.toTopBtn{
  transform-origin: bottom;
  padding: 1.7rem;
  position: fixed;
  border: none;
  right: 0;
  bottom: 0;
  border-top-left-radius: 25rem;
  background-color: #28282b;
  color: #EDECE3;
}

.toTopBtn:hover{
  cursor: pointer;
}

a{
  text-decoration: none;
  color: #28282B;
}
.imgWrapper{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
  width: 100%;
  height: 90vh;
  padding: 0 0 0 5rem;  
}

.colorBlob{
  width: 150%;
  filter: blur(15px);
} 

.main{
  overflow-x: hidden;
}

header{
  width: 90%;
  justify-content: space-between;
  padding: 2rem 0 0;
  color: #2a2d35;
}
.logoWrapper{
  background-color: #2a2d35;
  border-radius: 3px;
}
.logo{
  font-family: 'Bodoni Moda', serif;
  font-size: 4rem;
  line-height: 0.5;
  padding: 0 0.25rem 1rem;
  font-weight: 700;
  letter-spacing: -5px;  
  color: #EDECE3;
}
#menuIcon{
  display: none;
}
.menuWrapper{
  align-items: flex-end;
 }

.navBtn{
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.3rem;
  padding: .2rem .5rem .4rem; 
  background-color: #28282b2a;
  box-shadow: 0px 0px 2px #28282b76;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #EDECE3;
  margin-left: .5em;
}

.navBtn:hover{
  cursor: pointer;
  color: #2a2d35;
  background-color: #edece34f;
}

/*landing*/
.landing{
  width: 90%;
  height: 85vh;
}

.landingContentWrapper{
  height: 80%;
  animation: load 1.5s ease-in ;
}
@keyframes load {
  from { 
    filter: blur(10px);
  }
  to {filter: blur(0px);  }
}

.name{
  font-family: 'Bodoni Moda', serif;
  font-size: 9.5rem;
  font-weight: 700;
  font-style: italic;
  color: #2a2d35;
}


.subtext{
font-family: 'Darker Grotesque', sans-serif;
font-size: 2rem;
color: #2a2d35;
}

.projects, .about, .contact{
  margin-top: 5rem;
  width: 90%;
}

.sectionTitle{
  color: #2a2d35;
  line-height: 1;
  font-family: 'Bodoni Moda', serif;
  font-style: italic;
  letter-spacing: .3rem;
  font-size: 9.5rem;
}

/* projects */
.projectList{
  list-style: none;
  background: white;
}

.projectContainer{
  font-size: 2rem;
  padding: 1rem .5rem;
  border: #2a2d35 2px solid;
  border-bottom: none;
}
.projectTitleContainer{
  align-items: center;
  justify-content: flex-end;
  height: 4rem;
  transition: .5s;
}
.projectTitleContainer:hover{
  cursor: pointer;
}
.closed:hover{
  height: 5rem;
}
#packingAideContainer{
  background: linear-gradient(173deg, #D4E8FF80 30%, #f6f6f6 55%, #0d8cd4 55.2%);
  font-family: "Rubik", sans-serif;
}

#packingAideTitle{
  color: #f6f6f6;
  font-family: 'Raleway';
  text-shadow: -1px 1px #0077B5;
}
#packingAideTitleOpen{
  color: #0d8cd4;
  font-family: 'Raleway';
}
/* goalStrive */
#goalStriveContainer{
  background: linear-gradient(230deg, #558B6E, #E0EDC5);
  font-family: 'Poiret One', cursive;
  box-shadow: 0px -1px 10px #2e2e348f;
}

#goalStriveTitle{
  text-shadow: #2a332b 1px 1px;
  color: #e0edc4;
}

#photographyContainer{
  background-color: #f2f3f4;
  font-family: 'Roboto Condensed', sans-serif;
  box-shadow: 0px -1px 10px #2e2e348f;
}

#photographyTitle{
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 1px;
  color: #28282B;
}

#yoBurgerContainer{
  background-color:#fffaf0;
  box-shadow: 0px -1px 10px #2e2e348f;
  font-family: 'Voltaire', sans-serif;
}

#yoBurgerTitle{
  height: 3rem;
}

#quizzicalContainer{
  background-color: #F5F7FB;
  box-shadow: 0px -1px 10px #2e2e348f;
  font-family: 'Karla', sans-serif;
  position: relative;
  overflow: hidden;
  border-bottom: #2a2d35 3px solid;
}

#quizzicalTitle{
  font-family: 'Caprasimo', cursive;
  color: #293264;
  z-index: 1;
}

.yellowBlob{
  position:absolute;
  width: 30%;
  right: 0%;
  top: 0%;
}

.blueBlob{
  position:absolute;
  width: 30%;
  left: 0%;
  bottom: 0%;
}

.openProject{
  height: 30rem;
  overflow-y: hidden;
  color: #28282B;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}
.infoBar{

  margin: 1rem 0 .5rem;
}
.infoBar > a, .infoBar > p{
  padding: .5rem 1rem;
}
.projectLink{
  background: #2a2d35;
  color: #EDECE3;
  border: none;
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 0px 10px #28282b3d;
}
.projectLink:hover{
  background-color: #EDECE3;
  color: #2a2d35 ;
}
.mediaSide{
  width: 70%;
  margin-left: 1rem;
  z-index: 1;
}

.infoSide{
  width: 30%;
  padding: 0rem 1rem;
  z-index: 1;
}

.techStack{
  padding: .5rem;
  background: #2a2d35;
  color: #EDECE3;
  font-size: 1rem;
}
.media{
  overflow-y: auto;
  padding: 2rem;
  background: rgba(40, 40, 43, 0.1);
  box-shadow: 0px 0px 5px #28282b3d;
}

.media::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #EDECE3;
}

::-webkit-scrollbar-thumb {
  background: #2a2d35;
}

.videoContainer, .picture{
  width: 75%;
  position: relative;
  margin-bottom: 1rem;
}
.hoverIndicator{
  position: absolute;
  top: 30%;
  right: 45%;
  font-size: 5rem;
  color: #EDECE3;
  animation: pulse 1s linear 2s 5;
}
@keyframes pulse {
50%{
    scale: 1.2
}
100% {
    scale: 1 }
}

.videoOff{
  background-color: #28282b91;
  aspect-ratio: 16/9;
  position: absolute;
}

#hovered{
  width: 85%;
}

.left{
  align-self: flex-start;
}
.right{
  align-self: flex-end;
}

.headshot{
  width: 18rem;
  margin-left: 5rem;
  border-top-left-radius: 40rem;
  border-top-right-radius: 40rem;
}

.aboutTextWrapper{
  width: 50%;
  margin-left: 2rem;
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  align-items: flex-start;
}
.aboutText{
  padding-top: 1rem;
}
.aboutLink{
  padding: .5rem; 
  background-color: #28282b2a;
  outline: none;
}

#resume:hover{
  background: linear-gradient(58deg,rgb(114, 124, 133) 50%, rgb(128, 147, 125) 70%);
  color: #EDECE3;
}
#github:hover, #linkedin:hover{
  font-family:system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}
#github:hover{
  background-color: #010409;
  color: #e6edf3;
}
#linkedin:hover{
  color: #0077B5;
  background-color: #ffffff;
}

.aboutLink:hover{
  cursor: pointer;
  padding: .75rem; 
}
.formTitle{
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 2rem;
  color: #EDECE3;
}
form{
  margin: 3rem 0;
}

.input{
  background: none;
  border: none;
  border-bottom:#28282B .75px solid;
  outline: none;
  width: 400px;
  height: 40px;
  color: #EDECE3;
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.5rem;
}

.input:focus{
  background-color: #423e1845;
}
::placeholder{
  color: #EDECE3;
}
#message{
  height: 100px;
  resize: vertical;
}
#successMessage{
  margin-top: 1rem;
  font-size: 1.5rem;
  font-family: 'Darker Grotesque', sans-serif;
  color: #28282B;
}
#submit{
  margin-top: 1rem;
  padding: .8rem 1rem 1rem; 
  background-color: #28282b2a;
  box-shadow: 0px 0px 2px #28282b76;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #EDECE3;
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.5rem;
}
#submit:hover{
  cursor: pointer;
  color: #28282B;
  background-color: #edece34f;
}

@media screen and (max-width: 35.5em) {
  .imgWrapper{
    padding: 0 0 0rem 2rem;  
  }
  .colorBlob{
    width: 300%;
    height: 150%;
  } 

  .toTopBtn{
    padding: 1.5rem;
  }
  .arrow{
    font-size: 1.3rem;
  }

  nav{
    position: relative;
  }
  #menuIcon{
    display: block;
    color: #28282b;
  }
  .webMenu{
    display: none;
  }
  .mobileMenu{
    position: absolute;
    right: 0;
    top: 100%;
  }
  .navBtn{
    font-family: 'Darker Grotesque', sans-serif;
    font-size: 1.3rem;
    padding: 0.25rem 0;
    background: none;
    color: #2a2d35;
    box-shadow: none;
    border-radius: 0px;
    width: 100%;
    margin: 0;
  }
  .navBtn:hover{
    background-color: #edece364;
  }

  .name{
    font-size: 3rem;
  }
  .subtext{
    font-size: 1.5rem;
  }
  .sectionTitle{
    font-size: 2.5rem;
  }
 
  .projectContainer{
    font-size: 1.75rem;
    padding: 1rem .5rem;
  }
  .projectTitleContainer{
   height: 4rem;
  }
  .closed:hover{
    height: 110px;
  }

  #photographyTitle{
    font-size: 1.45rem;
  }
  #yoBurgerTitle{
    height: 2.75rem;
  }
  .openProject{
    height: 40rem;
    font-size: 1.2rem;
    overflow-y: auto;
    flex-direction: column;
    justify-content:flex-start;
    margin: 0;
  }
  .infoSide{
    width: 95%;
    padding: 0rem;
  }
  .techStack{
    padding: 0.5rem;
  }
  .infoBar{
    margin: 1rem 0 0.25rem;
    font-size: .8rem;
  }
  .infoBar > a, .infoBar > p{
    padding: .25rem .45rem;
  }
  .mediaSide{
    width: 100%;
  }
  .media{
    overflow-y:visible;
    padding: .5rem;
  }
  .openProject::-webkit-scrollbar {
    width: 5px;
  }
  .videoContainer, .picture{
    width: 85%;
  }
  #hovered{
    width: 90%;
  }
  
  .aboutContent{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .headshot{
    width: 13rem;
    margin: 0rem;
  }
  
  .aboutTextWrapper{
    width: 100%;
    margin: 0rem;
    font-size: 1.1rem;
  }
  .aboutText{
    padding-top: 0.5rem;
  }
  .aboutLink{
    padding: .15rem;
  }
  .aboutLink:hover{
    padding: .25rem;
  }

  form{
    margin: 5rem 0;
  }
  .input{
    width: 20rem;
  }
  #message{
    height: 10rem;
  }
  
  #submit{
    font-size: 1.2rem;
    padding: 0.75rem;
  }
}
@media screen and (min-width: 35.5em) and (max-width: 48em) {
  .imgWrapper{
    padding: 0 0 0rem 2rem;  
  }
  .colorBlob{
    width: 250%;
  } 
  .toTopBtn{
      padding: 1rem;
    }
  .arrow{
      font-size: 1rem;
  }
  .mobileNavBtn{
    display: none;
  }
  .name{
      font-size: 4rem;
    }
  .subtext{
      font-size: 1.5rem;
    }
    .sectionTitle{
      font-size: 4rem;
    }

    .projectContainer{
      font-size: 2rem;
    }
    .projectTitleContainer{
     height: 4rem;
    }
    .closed:hover{
      height: 75px;
    }

    #yoBurgerTitle{
      height: 2.75rem;
    }
    .openProject{
      height: 30rem;
      font-size: 1rem;
      overflow-y: auto;
      flex-direction: column;
      justify-content:flex-start;
      margin: 0;
    }
    .infoSide{
      width: 95%;
      padding: 0rem;
    }
    .techStack{
      padding: 0.5rem;
    }
    .infoBar{
      margin: .5rem 0 0.25rem;
    }
    .infoBar > a, .infoBar > p{
      padding: .5rem .4rem;
    }
    .mediaSide{
      width: 95%;
    }
    .media{
      overflow-y:visible;
      padding: 1rem;
    }
    
    .openProject::-webkit-scrollbar {
      width: 5px;
    }
    .videoContainer, .picture{
      width: 85%;
    }
  
    #hovered{
      width: 90%;
    }
    .aboutContent{
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
    }
    .headshot{
      width: 15rem;
      margin-left: 0rem;
    }
    
    .aboutTextWrapper{
      width: 100%;
      margin-left: 0rem;
      font-size: 1rem;
    }
    .aboutText{
      padding-top: 1rem;
    }
    .aboutLink{
      padding: .25rem;
    }
    .aboutLink:hover{
      padding: .5rem;
    }
    .input{
      width: 20rem;
    }
    #message{
      height: 8rem;
    }
    
    #submit{
      font-size: 1rem;
      padding: 0.75rem;
    }
}
@media screen and (min-width: 48em) and (max-width: 64em) {
  .imgWrapper{
    padding: 0 0 0rem 3rem;  
  }
  .colorBlob{
    width: 225%;
  }
    .toTopBtn{
      padding: 1rem;
    }
    .arrow{
      font-size: 1rem;
    }

    .logo{
      font-size: 3rem;
    }
    .name{
      font-size: 5.5rem;
    }
    .sectionTitle{
      font-size: 5.5rem;
    }
    .projectContainer{
      font-size: 2rem;
    }
    .projectTitleContainer{
      height: 4rem;
     }
    .closed:hover{
      height: 4.5rem;
    }
    #photographyTitle{
      font-size: 2.115rem;
    }
    #yoBurgerTitle{
      height: 2.75rem;
    }
    .openProject{
      height: 23rem;
      font-size: .8rem;
    }
    .infoBar{
      margin: .5rem 0;
    }
    .infoBar > a, .infoBar > p{
      padding: .4rem .5rem;
    }
    .infoSide{
      padding: 0rem;
    }
    .techStack{
      font-size: .75rem;
    }
    
    .hoverIndicator{
      font-size: 3rem;  
    }
    .headshot{
      width: 20rem;
      margin-left: 0rem;
    }
    
    .aboutTextWrapper{
      width: 55%;
      margin-left: 1rem;
      font-size: 1rem;
    }
    .aboutText{
      padding-top: 1rem;
    }
    
    .input{
      width: 25rem;
      height: 2rem;
      font-size: 1rem;
    }
    
    #message{
      height: 10rem;
    }
    
    #submit{
      font-size: 1rem;
    }
}
@media screen and (min-width: 64em) and (max-width: 75em){
  .imgWrapper{
    padding: 0 0 0rem 3rem;  
  }
  .colorBlob{
    width: 175%;
  }
    .toTopBtn{
      padding: 1rem;
    }
    .arrow{
      font-size: 1.25rem;
    }
    .logo{
      font-size: 3.5rem;
    }
    .name{
      font-size: 7.5rem;
    }
    .sectionTitle{
      font-size: 7.5rem;
    }


    .projectContainer{
      font-size: 3rem;
    }
   
    .closed:hover{
      height: 90px;
    }
    #photographyTitle{
      font-size: 3.15rem;
    }
    #yoBurgerTitle{
      height: 4.1rem;
    }

  
    .openProject{
      height: 25rem;
      font-size: 1rem;
    }
    .infoBar{
      margin: .5rem 0 .5rem;
    }
    .infoBar > a, .infoBar > p{
      padding: .5rem .5rem;
    }
    .infoSide{
      padding: 0rem 0.25rem;
    }
    .techStack{
      font-size: .80rem;
    }
    
    .hoverIndicator{
      font-size: 5rem;  
    }
    .headshot{
      width: 23rem;
      margin-left: 0rem;
    }
    
    .aboutTextWrapper{
      font-size: 1.3rem;
    }
    .aboutText{
      padding-top: 1rem;
    }
    
    .input{
      width: 30rem;
      height: 3rem;
      font-size: 1.3rem;
    }
    
    #message{
      height: 10rem;
    }
    
    #submit{
      font-size: 1.3rem;
    }
}
@media screen and (min-width:120em) and (max-width: 160em){
 

    .toTopBtn{
      padding: 1.75rem;
    }
    .arrow{
      font-size: 2rem;
    }
  
    .logo{
      font-size: 4rem;
      padding: 0 0.5rem 1.25rem;

    }
  
    .navBtn{
      font-size: 2rem;
    }
    .name{
      font-size: 14rem;
    }
    .subtext{
    font-size: 4rem;
    }
    .sectionTitle{
      font-size: 14.5rem;
    }
    
    .projectContainer{
      font-size: 4rem;
      padding: 2rem;
    }
    
    .closed:hover{
      height: 6rem;
    }
    #photographyTitle{
      font-size: 4rem;
    }
    #yoBurgerTitle{
      height: 5.5rem;
    }
    .openProject{
      height: 45rem;
      font-size: 1.75rem;
    }
    
    .techStack{
      font-size: 1.25rem;
    }
    
    .hoverIndicator{
      top: 35%;
      font-size: 7rem;  
    }
    .headshot{
      width: 35rem;
      margin-left: 5rem;
    }
    
    .aboutTextWrapper{
      font-size: 2.25rem;
    }
    .aboutText{
      padding-top: 1.5rem;
    }
    
    .input{
      width: 50rem;
      height: 4rem;
      font-size: 2.25rem;
    }
    #successMessage{
      font-size: 2.25rem;
    }
    #message{
      height: 20rem;
    }
    
    #submit{
      margin-top: 1rem;
      border-radius: 10px;
      font-size: 2.25rem;
    }
  
  
}
@media screen and (min-width:160em) { 
  
  .colorBlob{
    backdrop-filter: blur(20px);
  }
  .toTopBtn{
    padding: 2.5rem;
  }
  .arrow{
    font-size: 2.5rem;
  }

  .logo{
    font-size: 7rem;
    padding: 0 0.5rem 2rem;
  }
  
  .navBtn{
    font-size: 2.4rem;
  }

  .landingContentWrapper{
    height: 80%;
  }
  .name{
    font-size: 18rem;
  }
  .subtext{
  font-size: 6rem;
  }
  .sectionTitle{
    font-size: 20rem;
  }
  
  .projectContainer{
    font-size: 6rem;
  }
  .projectTitleContainer{
    height: 8rem;
  }
  .closed:hover{
    height: 10rem;
  }
  #photographyTitle{
    font-size: 6rem;
  }
  #yoBurgerTitle{
    height: 8rem;
  }

  .openProject{
    height: 60rem;
    font-size: 2.25rem;
  }
  .infoSide{
    padding: 0rem 1.5rem;
  }
  
  .techStack{
    font-size: 1.75rem;
  }

  .media::-webkit-scrollbar {
    width: 10px;
  }
  
  .hoverIndicator{
    font-size: 10rem;  
  }
  .headshot{
    width: 45rem;
  }
  
  .aboutTextWrapper{
    font-size: 3rem;
  }
  .formTitle{
    font-size: 4rem;
  }
  #successMessage{
    font-size: 3rem;
  }
  .input{
    width: 70rem;
    height: 6rem;
    font-size: 3rem;
  }

  
  #message{
    height: 25rem;
  }
  
  #submit{
    margin-top: 2rem;
    font-size: 3rem;
  }

} 

@media screen and (min-width:240em) { 
  
  .toTopBtn{
    padding: 4rem;
  }
  .arrow{
    font-size: 3.5rem;
  }
  .logo{
    font-size: 10rem;
    padding: 0 0.5rem 2.5rem;
  }

  .navBtn{
    font-size: 2.4rem;
  }
  .name{
    font-size: 28rem;
  }
  .subtext{
  font-size: 8rem;
  }
  .sectionTitle{
    font-size: 30rem;
  }
  
  .projectContainer{
    font-size: 10rem;
  }
  .projectTitleContainer{
    height: 15rem;
  }
  .closed:hover{
    height: 20rem;
  }
  #yoBurgerTitle{
    height: 13.69rem;
  }
  #photographyTitle{
    font-size: 10.53rem;
  }

  .openProject{
    height: 80rem;
    font-size: 3.5rem;
  }
  .infoSide{
    padding: 0rem 2rem;
  }
  
  .techStack{
    font-size: 3rem;
  }

  .media::-webkit-scrollbar {
    width: 10px;
  }
  
  .hoverIndicator{
    font-size: 15rem;  
  }
  .headshot{
    width: 68rem;
  }
  
  .aboutTextWrapper{
    font-size: 4.5rem;
  }
  .formTitle{
    font-size: 6rem;
  }
  .input{
    width: 80rem;
    height: 10rem;
    font-size: 4.5rem;
  }
  #successMessage{
    font-size: 4.5rem;
  }

  #message{
    height: 30rem;
  }
  
  #submit{
    font-size: 4.5rem;
  }

} 
